jQuery(function($) {

 var $slider = $('.testimonial-slider');

  if ($slider.length) {
    var currentSlide;
    var slidesCount;
    var sliderCounter = document.createElement('div');
    sliderCounter.classList.add('slider__counter');
    
    var updateSliderCounter = function(slick, currentIndex) {
      currentSlide = slick.slickCurrentSlide() + 1;
      if(currentSlide < 10){
        currentSlide = '0'+currentSlide;
      }
      slidesCount = slick.slideCount;
      if(slidesCount < 10){
        slidesCount = '0'+slidesCount;
      }
      $(sliderCounter).html('<strong>' + currentSlide+'</strong>' + ' ' + slidesCount)
    };
  
    $slider.on('init', function(event, slick) {
      $slider.append(sliderCounter);
      updateSliderCounter(slick);
    });
  
    $slider.on('afterChange', function(event, slick, currentSlide) {
      updateSliderCounter(slick, currentSlide);
    });

  // added progress bar css

    var $progressLabel = $('.slider__label');

    $slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
      var calc = ((nextSlide) / (slick.slideCount + 0)) * 100;
  
      $progressLabel.css({
        'left': calc + '%',
        'width': 100 / (slick.slideCount + 0) + '%'
      });
  
      $progressLabel.text(calc.toFixed(1) + '% completed');
    });

  // end progress bar css

  
    $slider.slick({
      autoplay: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
      ]    
    });
  }
});